.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }
  #jetmenu {
    display: none;
  }
  .container.open #jetmenu {
    display: block;
  }
}

.App {
  overflow-x: hidden; 
}


.dmtop {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #007bff;
  color: white; 
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7; 
  transition: opacity 0.3s;
  z-index: 1000;
}

.dmtop:hover {
  opacity: 1;
}

.text-start {
  text-align: left;
}
